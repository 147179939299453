<template>
    <v-container class="container--fluid grid-list-md">
        <div class="d-flex justify-space-between">
            <div class="d-flex">
                <h2>{{ $t('route.catalog.rozetka') }}</h2>
                <v-btn
                    outlined
                        small
                        dark
                        color="green"
                        class="ml-5"
                        @click="$router.push('/category/new')"
                >
                    <v-icon small class="mr-2">mdi-folder-plus</v-icon>
                    {{ $t("btns.new_category") }}
                </v-btn>
            </div>
            <v-switch
                    class="ma-0 pa-0 v-input--reverse"
                    v-model="allView"
                    :label="allView? $t('fields.from_group'): $t('fields.expand_cat')"
            ></v-switch>
        </div>
        <div
                class="wrap-progress"
        >
            <v-progress-linear
                    indeterminate
                    height="2"
                    light
                    absolute
                    :active="getLoading"
            ></v-progress-linear>
        </div>
        <custom-table
                :headers="headers"
                :items="categories"
                @openFolder="folder"
                @eSort="eSort"
                @send="open"
                :isfolder="true"
                :actions="[
        {
          title: $t('btns.edit'),
          action: 'edit',
          func: (data) => open(data, 'edit'),
        },
        ]"
        />
        <div class="text-center">
            <v-pagination
                    v-if="allPage"
                    class="mt-2"
                    v-model="currentPage"
                    :length="allPage"
                    :total-visible="7"
            ></v-pagination>
        </div>

    </v-container>

</template>

<script>
    import {mapActions, mapGetters, mapMutations} from "vuex";

    export default {
        name: 'Categories',
        data() {
            return {
                allView: false,
                arr_subcategories: [],
                categoriesList: [],
                currentPage: 1,
                allPage: null,
                headers: [
                    {
                        text: this.$t('fields.name2'),
                        value: "name",
                        width: 500,
                        sortable: true,
                        edit: false,
                        search: true,
                        template: 'name',
                    },
                    {
                        text: this.$t('fields.amount_products'),
                        value: "productsCount",
                        width: 200,
                        sortable: true,
                        edit: false,
                    },
                    {
                        text: "ID",
                        value: "id",
                        width: 130,
                        sortable: true,
                        edit: false,
                        search: true,
                    },
                ],
                params: {
                    'filter[parent]': 0,
                    'cexpand': 'productsCount'
                }
            }

        },

        mounted() {
            this.getApiCategories(this.params)
        },
        computed: {
            ...mapGetters(["getCats", "getLoading"]),
            categories() {
                let arr = []
                for (let category of this.categoriesList) {
                    let obj = {
                        ...category.translations[0],
                        ...category,
                    }
                    arr.push(obj)
                }
                return arr
            }
        },
        watch: {
            currentPage() {
                this.params.page = this.currentPage
                this.getApiCategories(this.params)
            },
            allView() {
                if (this.allView) {
                    this.params = {}
                    this.params.cexpand = 'productsCount'
                    this.currentPage = 1,
                        this.getApiCategories(this.params)
                } else {
                    this.params["filter[parent]"] = 0
                    this.getApiCategories(this.params)
                }
            }
        },
        methods: {
            ...mapActions(["initCat", "initCat2", "initProducts"]),
            ...mapMutations([]),
            open(data, func = null) {
                if (func === "edit") {
                    this.$router.push(`/category/${data.id}`)
                } else this.$router.push(`products/category/${data.id}`)
            },
            eSort(sort) {
                console.log(sort)
                this.params.sort = sort.dir === "desc" ? "-" + sort.by : sort.by
                this.getApiCategories(this.params)
            },
            getApiCategories(params) {
                this.initCat(params).then(function () {
                    let response = this.getCats
                    this.categoriesList = response.items
                    this.allPage = response._meta.pageCount
                    this.arr_subcategories = []
                }.bind(this))

            },
            folder(item) {
                if (this.arr_subcategories.find(v => v === item.id) === undefined) {
                    this.arr_subcategories.push(item.id)
                    this.params["filter[parent]"] = item.id
                    this.params.add = true,
                        this.initCat2(this.params).then(function () {
                            this.categoriesList = this.getCats.items
                            this.params["filter[parent]"] = 0
                        }.bind(this))
                }

            },
        }
    };
</script>

<style lang="scss">
    .v-input--reverse .v-input__slot {
        flex-direction: row-reverse;
        justify-content: flex-end;

        .v-application--is-ltr & {
            .v-input--selection-controls__input {
                margin-right: 0;
                margin-left: 8px;
            }
        }
    }
</style>
